import React from 'react';
import { Link } from 'react-router-dom';

import sliderLogo from '../../images/banner-logp.png';

function HomeMainSlider() {
	return (
		<section className='main-slider'>
			<div className='jumbtron'>
				<div className='container'>
					<div className='banner text-center'>
						<img src={sliderLogo} alt='' />
						<h2>ΦΑΝΟΠΟΙΕΙΑ - ΒΑΦΕΣ - ΣΥΓΚΟΛΛΗΣΕΙΣ</h2>
						<div className='text-wrapper'>
							<p>Με πολυετή εμπειρία και τεχνογνωσία, βασικό μας μέλημα είναι η ουσιαστική και ποιοτική παροχή υπηρεσιών προς τον πελάτη.</p>
						</div>
						<Link to='/contact' className='cta btn btn-lg btn-warning'>
							<b>Δωρεάν Εκτίμηση Εργασιών</b>
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
}

export default HomeMainSlider;
