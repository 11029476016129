import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Footer from './Footer';
import Home from '../Pages/Home/Home';
import About from '../Pages/About/About';
import Services from '../Pages/Services/Sevices';
import Contact from '../Pages/Contact/Contact';
import PhotoGallery from '../Pages/PhotoGallery/PhotoGallery';

function MainSection() {
	return (
		<div className='page-wrapper'>
			<Switch>
				<Route exact path='/'>
					<Home />
				</Route>
				<Route path='/about'>
					<About />
				</Route>
				<Route path='/services'>
					<Services />
				</Route>
				<Route path='/contact'>
					<Contact />
				</Route>
				<Route path='/gallery'>
					<PhotoGallery />
				</Route>
			</Switch>

			<Footer />
		</div>
	);
}

export default MainSection;
