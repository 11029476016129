import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';

import aboutImage from '../../images/IMG_3915.jpg';

function About() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<section id='about-us' className='tabs-section dark'>
			<div className='auto-container'>
				<div className='row clearfix'>
					<div className='col-md-12 col-sm-12 col-xs-12'>
						<div className='sec-title'>
							<h2 className='skew-lines'>Σχετικά με εμάς</h2>
						</div>
						<div className='col-md-8 col-sm-12 col-xs-12 text-content'>
							<div className='text'>
								<p>
									Το Φανοποιείο – Βαφείο αυτοκινήτων <strong>CRC-Auto Paint</strong> στην Αργυρούπολη, λειτουργεί από το 2012 σε έναν χώρο που
									διαθέτει φούρνο και μηχανήματα τελευταίας τεχνολογίας. Ο ιδιοκτήτης της επιχείρησης, Νικόλαος Κρομύδας, δραστηριοποιείται στο χώρο
									των βαφών και της φανοποιϊας ήδη από το 2000.
								</p>
								<p>
									Η πολύχρονη εμπειρία και η συνεχόμενη συνεργασία με κορυφαία ονόματα στο χώρο του αυτοκινήτου, οι ασυναγώνιστες τιμές και η άμεση
									και υπεύθυνη εξυπηρέτηση συνθέτουν το επαγγελματικό προφίλ, το οποίο εμπιστεύονται τόσα χρόνια οι πελάτες μας.
								</p>
								<p>
									Αναλαμβάνουμε την διαδικασία για την αποζημίωση απο την ασφαλιστική σας και μπορούμε να σας προσφέρουμε αυτοκίνητο αντικατάστασης
									για το διάστημα εργασιών αποκατάστασης του οχήματός σας.
								</p>
								<p>
									Στο φανοβαφείο <strong>CRC-Auto Paint</strong> μπορούμε να εξυπηρετήσουμε και τους πιο απαιτητικούς πελάτες που δίνουν έμφαση στη
									λεπτομέρεια και το καλό, ποιοτικό αποτέλεσμα, δημιουργώντας παράλληλα φιλικές σχέσεις και μετέπειτα συνεργασίες που κρατάνε για
									καιρό.
								</p>
							</div>
						</div>

						<div className='col-md-4 col-sm-12 col-xs-12'>
							<div className='image-container'>
								<img src={aboutImage} alt='crc shop' />
							</div>
						</div>
					</div>
				</div>
				<div className='row clearfix'>
					<div className='col-md-12 col-sm-12 col-xs-12'>
						<div className='player-wrapper image-container'>
							<ReactPlayer className='react-player' url='https://www.youtube.com/watch?v=rLwnA8Q2EoY' width='100%' height='100%' />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default About;
