import React from 'react';

import './css/styles.css';
import './css/responsive.css';

import TopSection from './Layout/TopSection';
import MainSection from './Layout/MainSection';

function App() {
	return (
		<div className='App'>
			<TopSection />
			<MainSection />
		</div>
	);
}

export default App;
