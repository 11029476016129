const image1 = require('../../images/1.jpg');
const image2 = require('../../images/2.jpg');
const image3 = require('../../images/3.jpg');
const image4 = require('../../images/4.jpg');
const image5 = require('../../images/5.jpg');
const image6 = require('../../images/6.jpg');
const image7 = require('../../images/7.jpg');
const image8 = require('../../images/8.jpg');
const image9 = require('../../images/9.jpg');
const image10 = require('../../images/10.jpg');
const image11 = require('../../images/11.jpg');
const image12 = require('../../images/12.jpg');
const image13 = require('../../images/13.jpg');
const image14 = require('../../images/14.jpg');
const image15 = require('../../images/15.jpg');

export const photos = [
	{
		src: image1,
		width: 3,
		height: 4,
	},
	{
		src: image5,
		width: 4,
		height: 3,
	},

	{
		src: image3,
		width: 3,
		height: 4,
	},
	{
		src: image4,
		width: 4,
		height: 3,
	},
	{
		src: image2,
		width: 3,
		height: 4,
	},
	{
		src: image6,
		width: 4,
		height: 3,
	},
	{
		src: image10,
		width: 3,
		height: 4,
	},

	{
		src: image8,
		width: 4,
		height: 3,
	},
	{
		src: image9,
		width: 4,
		height: 3,
	},
	{
		src: image7,
		width: 4,
		height: 3,
	},
	{
		src: image11,
		width: 3,
		height: 4,
	},
	{
		src: image12,
		width: 3,
		height: 4,
	},
	{
		src: image13,
		width: 3,
		height: 4,
	},
	{
		src: image14,
		width: 3,
		height: 4,
	},
	{
		src: image15,
		width: 3,
		height: 4,
	},
];
