import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

function Contact() {
	const [mailResponse, setMailResponse] = useState({ status: null, message: null });
	const { register, handleSubmit, watch, errors, setError, clearError, reset } = useForm();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const eOffer = watch('eOffer', false);
	// validate the name and subject as it is only letters (for english and greek languages)
	const validText = /^[0-9A-Za-zΑ-Ωα-ωςίϊΐόάέύϋΰήώ'-@!; ]+$/;
	// validate the mail
	const validMail = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

	// check the number of attached files do not exceed 5mb (ZEIT NOW Serverless Function Payload Size Limit is 5Mb)
	const fileChangedHandler = (event) => {
		clearError('attachments');
		if (event.target.files.length > 0) {
			let totalSize = 0;
			for (var i = 0; i < event.target.files.length; i++) {
				let file = event.target.files[i];
				totalSize += file.size / 1024 / 1024;
			}
			// round number to 2 decimals at max
			totalSize = Math.round((totalSize + Number.EPSILON) * 100) / 100;
			if (totalSize >= 5)
				return setError(
					'attachments',
					'notMatch',
					'Το συνολικό μέγεθος των αρχείων σας ξεπερνάει τα 5Mb. Επιλέξτε αρχεία με λιγότερο συνολικό μεγεθος',
				);
		}
		return;
	};

	// function to convert files to base64
	function toBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	// send mail to backend
	const sendMail = async (formdata) => {
		setMailResponse({ status: 'SENDING', message: 'Αποστολή μηνύματος...' });
		const attchbase64 = [];
		if (formdata.attachments) {
			for (var i = 0; i < formdata.attachments.length; i++) {
				let file = formdata.attachments[i];
				await toBase64(file).then((data) => attchbase64.push({ path: data }));
			}
			formdata.attachments = attchbase64;
		}
		console.log(formdata);
		fetch('/api/sendMail', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(formdata),
		})
			.then((response) => {
				console.log(response);
				if (response.status === 200) {
					setMailResponse({ status: 'OK', message: 'Το μυνημά εστάλει με επιτυχία' });
					reset();
				} else {
					setMailResponse({ status: 'ERROR', message: 'Το μηνυμα δεν μπορεσε να αποσταλεί. Παρακαλώ προσπαθηστε ξανα' });
				}
			})
			.catch((error) => {
				setMailResponse('Error:', error);
			});
	};

	return (
		<section className='tabs-section'>
			<div className='auto-container'>
				<div className='row clearfix'>
					<section className='col-xs-12'>
						{/* <!-- Contact Form --> */}
						<div className='contact-form'>
							<div className='sec-title'>
								<h2 className='skew-lines'>Επικοινωνήστε μαζί μας</h2>
							</div>
							<div className='msg-text'>
								Συμπληρώστε όλα τα πεδία για να μας στείλετε το μύνημά σας.
								<br /> Εάν θέλετε να λάβετε μια οικονομική εκτίμηση για τις εργασίες που επιθυμείτε, πατήστε την επιλογή{' '}
								<strong>
									<ins>"Επιθυμώ να αποστειλω..."</ins>
								</strong>{' '}
								κάτω από το θέμα του μηνύματός σας.
							</div>

							{/* <!--Contact Form--> */}
							{mailResponse?.status === 'OK' && (
								<div className='alert alert-success' role='alert'>
									{mailResponse?.message}
								</div>
							)}
							{mailResponse?.status === 'ERROR' && (
								<div className='alert alert-danger' role='alert'>
									{mailResponse?.message}
								</div>
							)}
							{mailResponse?.status === 'SENDING' && (
								<div className='alert alert-info' role='alert'>
									{mailResponse?.message}
								</div>
							)}

							<form id='contact-form' onSubmit={handleSubmit(sendMail)}>
								<div className='row clearfix'>
									<div className='col-md-5 col-sm-12 col-xs-12'>
										<div className='form-group'>
											<label className='form-label'>Ονοματεπώνυμο</label>
											<input type='text' name='name' placeholder='Γράψτε το όνομά σας...' ref={register({ required: true, pattern: validText })} />
											<div className='help-block text-danger'>
												{errors.name?.type === 'required' && 'Συμπληρώστε το όνομά σας.'}
												{errors.name?.type === 'pattern' && 'Μόνο αλφανουμερικοί χαρακτήρες και το κενό επιτρέπονται.'}
											</div>
										</div>

										<div className='form-group'>
											<label className='form-label'>Email</label>
											<input type='text' name='email' placeholder='Γράψτε το email σας...' ref={register({ required: true, pattern: validMail })} />
											<div className='help-block text-danger'>
												{errors.email?.type === 'required' && 'Συμπληρώστε το email σας.'}
												{errors.email?.type === 'pattern' && 'Συμπληρώστε μια έγκυρη διεύθυνση email.'}
											</div>
										</div>

										<div className='clearfix'></div>

										<div className='form-group'>
											<label className='form-label'>Θέμα</label>
											<input type='text' name='subject' placeholder='Γράψτε το θέμα του μηνύματος...' ref={register({ pattern: validText })} />
											<div className='help-block text-danger'>
												{errors.subject?.type === 'pattern' && 'Μόνο αλφανουμερικοί χαρακτήρες και το κενό επιτρέπονται.'}
											</div>
										</div>
										<div className='form-group'>
											<label className='container'>
												<small>
													Επιθυμώ να αποστείλω φωτογραφίες μου, για την ηλεκτρονική εκτίμηση του κόστους των εργασιών αποκατάστασης του οχήματός μου
												</small>
												<input type='checkbox' name='eOffer' defaultChecked={false} ref={register} />
												<span className='checkmark'></span>
											</label>
										</div>
									</div>

									<div className='col-md-7 col-sm-12 col-xs-12'>
										<div className='form-group'>
											<label className='form-label'>Μήνυμα</label>
											<textarea name='message' placeholder='Γράψτε το μήνυμά σας...' ref={register({ required: true })}></textarea>
											<div className='help-block text-danger'>{errors.message?.type === 'required' && 'Συμπληρώστε το μήνυμά σας.'}</div>
										</div>

										{eOffer && (
											<div className='form-group'>
												<label htmlFor='#contained-button-file'>Eπισύναψη Φωτογραφιών</label>
												<div className='help-block text-warning'>Μεγιστο επιτρεπόμενο συνολικό μέγεθος είναι 5mb </div>
												<input
													type='file'
													multiple
													name='attachments'
													accept='image/*'
													id='contained-button-file'
													ref={register({ required: true })}
													onChange={(e) => fileChangedHandler(e)}
												/>
												<div className='help-block text-danger'>
													{errors.attachments?.type === 'required' && 'Δεν έχετε επισυνάψει καμια φωτογραφία.'}
													{errors.attachments && errors.attachments.message}
												</div>
											</div>
										)}
										<div className='form-group text-right'>
											<button type='submit' name='submit-form' className='cta btn btn-warning'>
												<span className='fa fa-envelope'></span> Αποστολή Μηνύματος
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</section>
				</div>
			</div>
			<section style={{ position: 'relative', overflow: 'hidden', marginTop: '30px' }}>
				<iframe
					title='crcMap'
					src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3147.57100340305!2d23.753578015621965!3d37.917088879733754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1bdfa6d5c8751%3A0xb48a47300f9a8ece!2zQ1JDIM6mzpHOnc6fzpLOkc6mzpXOoyAtIM6mzpHOnc6fzqDOn86ZzpnOkSAtIM6SzpHOps6VzqMgLSDOms6hzp_OnM6czqXOlM6RzqMgzp3Omc6azp_OoyAtIM6RzqHOk86lzqHOn86lzqDOn86bzpc!5e0!3m2!1sen!2sgr!4v1589819517515!5m2!1sen!2sgr'
					width='100%'
					height='300'
					frameborder='0'
					style={{ border: '0' }}
					allowfullscreen=''
					aria-hidden='false'
					tabindex='0'
				></iframe>
			</section>
		</section>
	);
}

export default Contact;
