import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
	return (
		<footer className='main-footer'>
			{/* <!--Footer Upper--> */}
			<div className='footer-upper'>
				<div className='auto-container'>
					<div className='row clearfix'>
						{/* <!--Footer Widget--> */}
						<div className='col-md-3 col-sm-12 col-xs-12'>
							<div className='footer-widget contact-widget'>
								<h3>Επικοινωνια</h3>

								<ul className='info'>
									<li>
										<strong>Διεύθυνση</strong> <span>Νίκης 19, Αργυρούπολη</span>
									</li>
									<li>
										<strong>Email</strong> <span>crcautorepair@gmail.com</span>
									</li>
									<li>
										<strong>Τηλέφωνο</strong> <span>(210) 99 17 703</span>
									</li>
									<li>
										<strong>Fax</strong> <span>(210) 99 17 883</span>
									</li>
									<li>
										<strong>Κινητό</strong> <span>6980 482 182</span>
									</li>
									<li>
										<strong>Website</strong> <a href='https://crc-autopaint.gr/'>www.crc-autopaint.gr</a>
									</li>
									<li>
										<Link to='/contact'>
											<strong>Φόρμα Επικοινωνίας </strong>
										</Link>
									</li>
								</ul>
							</div>
						</div>

						{/* <!--Footer Widget--> */}
						<div className='col-md-4 col-sm-12 col-xs-12'>
							<div className='footer-widget services-widget'>
								<h3>Οι Υπηρεσιες μας</h3>
								<ul className='links'>
									<li>
										<Link to={{ pathname: '/services', state: { active: 'tab-1' } }}>
											<i className='fa fa-check-circle'></i>Φανοποιεία - Βαφές
										</Link>
									</li>
									<li>
										<Link to={{ pathname: '/services', state: { active: 'tab-2' } }}>
											<i className='fa fa-check-circle'></i>Συγκολήσεις πλαστικού - αλλουμινίου
										</Link>
									</li>
									<li>
										<Link to={{ pathname: '/services', state: { active: 'tab-3' } }}>
											<i className='fa fa-check-circle'></i>Service A/C
										</Link>
									</li>
									<li>
										<Link to={{ pathname: '/services', state: { active: 'tab-4' } }}>
											<i className='fa fa-check-circle'></i>Διεκπεραίωση αποζημίωσης
										</Link>
									</li>
									<li>
										<Link to={{ pathname: '/services', state: { active: 'tab-5' } }}>
											<i className='fa fa-check-circle'></i>Προετοιμασία προς πώληση
										</Link>
									</li>
									<li>
										<Link to={{ pathname: '/services', state: { active: 'tab-6' } }}>
											<i className='fa fa-check-circle'></i>Αυτοκίνητο εξυπηρέτησης
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className='col-md-5 col-sm-12 col-xs-12'>
							<div className='footer-widget'>
								<h3>Χαρτης</h3>
								<div id='map' className='leaflet-container'>
									<iframe
										title='crcmap'
										src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3147.57100340305!2d23.753578015621965!3d37.917088879733754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1bdfa6d5c8751%3A0xb48a47300f9a8ece!2zQ1JDIM6mzpHOnc6fzpLOkc6mzpXOoyAtIM6mzpHOnc6fzqDOn86ZzpnOkSAtIM6SzpHOps6VzqMgLSDOms6hzp_OnM6czqXOlM6RzqMgzp3Omc6azp_OoyAtIM6RzqHOk86lzqHOn86lzqDOn86bzpc!5e0!3m2!1sen!2sgr!4v1589819517515!5m2!1sen!2sgr'
										width='600'
										height='450'
										frameborder='0'
										style={{ border: '0' }}
										allowfullscreen=''
										aria-hidden='false'
										tabindex='0'
									></iframe>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <!--Footer Bottom--> */}
			<div className='footer-bottom'>
				<div className='auto-container'>
					<div className='row clearfix'>
						<div className='col-md-6 col-sm-12 col-xs-12'>
							<div className='copyright'>Copryright 2020 by CRC Autopaint | All rights reserved</div>
						</div>
						<div className='col-md-6 col-sm-12 col-xs-12'>
							<div className='social-links'>
								<Link
									to={
										'//www.facebook.com/CRC-%CE%BA%CE%AD%CE%BD%CF%84%CF%81%CE%BF-%CE%B5%CF%80%CE%B9%CF%83%CE%BA%CE%B5%CF%85%CE%AE%CF%82-%CE%B1%CF%85%CF%84%CE%BF%CE%BA%CE%B9%CE%BD%CE%AE%CF%84%CE%BF%CF%85-524429947672359/'
									}
									target='_blank'
									rel='noopener'
									className='fa fa-facebook-f'
									aria-hidden='true'
								></Link>

								<Link
									to={'//www.youtube.com/channel/UCtqQbYNEFAtyTfyaS8VIKNQ/featured'}
									target='_blank'
									className='fab fa-youtube'
									aria-hidden='true'
									rel='noopener'
								></Link>
								<Link to={'//www.instagram.com/'} target='_blank' rel='noopener' className='fa fa-instagram' aria-hidden='true'></Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
