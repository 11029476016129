import React from 'react';
import { Link } from 'react-router-dom';

function HomeServices() {
	return (
		<section id='home-services' className='services'>
			<div className='auto-container'>
				<div className='row clearfix'>
					<div className='sec-title'>
						<h2 className='skew-lines'>Οι Υπηρεσίες μας</h2>
					</div>
					<div className='col-sm-6 col-xs-12 box-container'>
						<Link to={{ pathname: '/services', state: { active: 'tab-1' } }} className='tab-link'>
							<div className='icon'>
								<span className='fas fa-tools'></span>
							</div>
							<h4>ΦΑΝΟΠΟΙΕΙΑ - ΒΑΦΕΣ</h4>
							<p>Επισκευή αυτοκινήτου</p>
						</Link>
					</div>
					<div className='col-sm-6 col-xs-12 box-container'>
						<Link to={{ pathname: '/services', state: { active: 'tab-2' } }} className='tab-link'>
							<div className='icon'>
								<span className='fas fa-puzzle-piece'></span>
							</div>
							<h4>ΣΥΓΚΟΛΗΣΕΙΣ</h4>
							<p>Αλουμινίου - Πλαστικού</p>
						</Link>
					</div>
					<div className='col-sm-6 col-xs-12 box-container'>
						<Link to={{ pathname: '/services', state: { active: 'tab-3' } }} className='tab-link'>
							<div className='icon'>
								<span className='fas fa-fan'></span>
							</div>
							<h4>SERVICE A/C</h4>
							<p>Αναπλήρωση φρέον</p>
						</Link>
					</div>
					<div className='col-sm-6 col-xs-12 box-container'>
						<Link to={{ pathname: '/services', state: { active: 'tab-4' } }} className='tab-link'>
							<div className='icon'>
								<span className='fas fa-car-crash'></span>
							</div>
							<h4>ΔΙΕΚΠΕΡΑΙΩΣΗ ΑΠΟΖΗΜΙΩΣΗΣ</h4>
							<p>Με όλες τις ασφαλιστικές</p>
						</Link>
					</div>
					<div className='col-sm-6 col-xs-12 box-container'>
						<Link to={{ pathname: '/services', state: { active: 'tab-5' } }} className='tab-link'>
							<div className='icon'>
								<span className='fas fa-exchange-alt'></span>
							</div>
							<h4>ΠΡΟΕΤΟΙΜΑΣΙΑ ΠΡΟΣ ΠΩΛΗΣΗ</h4>
							<p>Aνανέωση αυτοκινήτου και μικροεπισκευές</p>
						</Link>
					</div>
					<div className='col-sm-6 col-xs-12 box-container'>
						<Link to={{ pathname: '/services', state: { active: 'tab-6' } }} className='tab-link'>
							<div className='icon'>
								<span className='fas fa-car'></span>
							</div>
							<h4>ΑΥΤΟΚΙΝΗΤΟ ΕΞΥΠΗΡΕΤΗΣΗΣ</h4>
							<p>Για να μην μείνετε χωρίς αυτοκίνητο</p>
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
}

export default HomeServices;
