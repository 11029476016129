import React from 'react';
import { Link } from 'react-router-dom';

import aboutImage from '../../images/IMG_3915.jpg';

function HomeAbout() {
	return (
		<section id='home-about-us' className='about-us dark'>
			<div className='auto-container'>
				<div className='row clearfix'>
					<div className='sec-title'>
						<h2 className='skew-lines'>Σχετικά με εμάς</h2>
					</div>
					<div className='col-md-8 col-sm-12 col-xs-12 text-content'>
						<div className='text'>
							<p>
								Το Φανοποιείο – Βαφείο αυτοκινήτων CRC-Auto Paint στην Αργυρούπολη, λειτουργεί από το 2012 σε έναν χώρο που διαθέτει φούρνο και
								μηχανήματα τελευταίας τεχνολογίας. Ο ιδιοκτήτης της επιχείρησης, Νικόλαος Κρομύδας, δραστηριοποιείται στο χώρο των βαφών και της
								φανοποιϊας ήδη από το 2000.
							</p>
							<p>
								Η πολύχρονη εμπειρία και η συνεργασία του με κορυφαία ονόματα στο χώρο του αυτοκινήτου, οι ασυναγώνιστες τιμές και η άμεση και
								υπεύθυνη εξυπηρέτηση συνθέτουν το επαγγελματικό προφίλ, το οποίο εμπιστεύονται τόσα χρόνια οι πελάτες μας.
							</p>
							<div className='text-right link'>
								<Link to='/about' className='theme-btn dark-btn'>
									περισσότερα
								</Link>
							</div>
						</div>
					</div>
					<div className='col-md-4 col-sm-12 col-xs-12'>
						<div className='image-container'>
							<img src={aboutImage} alt='crc shop' />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default HomeAbout;
