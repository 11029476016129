import React from 'react';
import { Link } from 'react-router-dom';

function HomeEvaluate() {
	return (
		<section className='intro-section evaluate dark'>
			<div className='auto-container'>
				<div className='clearfix'>
					<div className='col-xs-12 text-center'>
						<h3 style={{ marginBottom: '30px' }}>Στείλετε μας τις φωτογραφίες σας για μια Δωρεάν Εκτίμηση Εργασιών</h3>
						<Link to='/contact' className='cta btn btn-lg btn-warning'>
							<b>Δωρεάν Εκτίμηση Εργασιών</b>
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
}

export default HomeEvaluate;
