import React, { useEffect } from 'react';
import MainSlider from './Home_MainSlider';
import HomeAbout from './Home_About';
import HomeServices from './Home_Services';
import HomeEvaluate from './Home_Evaluate';

const Home = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<MainSlider />
			<HomeAbout />
			<HomeServices />
			<HomeEvaluate />
		</>
	);
};

export default Home;
