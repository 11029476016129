import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

import logo from '../images/logo.png';
import phone from '../images/header-phone.png';
import timer from '../images/header-timer.png';

function TopSection() {
	const [collapsed, setCollapsed] = useState(true);

	const toggleNavbar = () => {
		setCollapsed(!collapsed);
	};

	const hide = (e) => {
		if (e && e.relatedTarget) {
			e.relatedTarget.click();
		}
		setCollapsed(true);
	};

	return (
		<header className='main-header fixed-top-header'>
			<div className='header-top'>
				<div className='auto-container'>
					<div className='row clearfix'>
						{/* <!--Logo--> */}
						<div className='col-md-3 col-sm-3 col-xs-12 logo'>
							<NavLink to='/'>
								<img src={logo} alt='CRC-auto paint' title='CRC-auto paint' />
							</NavLink>
						</div>

						<div className='col-lg-5 col-md-6 col-12 header-top-infos pull-right'>
							<ul className='clearfix'>
								<li>
									<div className='clearfix'>
										<img src={phone} alt='' style={{ marginRight: '20px' }} />
										<p>
											Tηλέφωνο
											<br />
											<b>(210) 99 17 703</b>
											<br />
											Κινητό
											<br />
											<b>6980 482 182</b>
										</p>
									</div>
								</li>
								<li>
									<div className='clearfix'>
										<img src={timer} alt='' style={{ marginRight: '20px' }} />
										<p>
											Δευτέρα - Παρασκευή
											<br />
											<b>09:00 - 19:00</b>
											<br />
											Σάββατο
											<br />
											<b>09:00 - 15:00</b>
										</p>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			{/* <!--Header Lower--> */}
			<div className='header-lower'>
				<div className='auto-container'>
					<div className='row clearfix'>
						{/* <!--Main Menu--> */}
						<nav className='col-md-9 col-sm-12 col-xs-12 main-menu'>
							<div className='navbar-header'>
								{/* <!-- Toggle Button --> */}
								<button
									type='button'
									className='navbar-toggle'
									data-toggle='collapse'
									data-target='.navbar-collapse'
									onClick={toggleNavbar}
									onBlur={hide}
								>
									<span className='icon-bar'></span>
									<span className='icon-bar'></span>
									<span className='icon-bar'></span>
								</button>
							</div>

							<div className={`navbar-collapse collapse ${collapsed ? '' : 'in'} clearfix`}>
								<ul className='navigation'>
									<li>
										<NavLink exact to='/' activeClassName='current'>
											ΑΡΧΙΚΗ
										</NavLink>
									</li>
									<li>
										<NavLink to='/about' activeClassName='current'>
											ΣΧΕΤΙΚΑ ΜΕ ΕΜΑΣ
										</NavLink>
									</li>
									<li>
										<NavLink to='/services' activeClassName='current'>
											ΥΠΗΡΕΣΙΕΣ
										</NavLink>
									</li>
									<li>
										<NavLink to='/gallery' activeClassName='current'>
											ΦΩΤΟΓΡΑΦΙΕΣ
										</NavLink>
									</li>
									<li>
										<NavLink to='/contact' activeClassName='current'>
											EΠΙΚΟΙΝΩΝΙΑ
										</NavLink>
									</li>
								</ul>
							</div>
						</nav>
						{/* <!--Main Menu End--> */}

						{/* <!--Social NavLinks--> */}
						<div className='col-md-3 col-sm-12 col-xs-12 social-outer'>
							<div className='social-links text-right'>
								<Link
									to={
										'//www.facebook.com/CRC-%CE%BA%CE%AD%CE%BD%CF%84%CF%81%CE%BF-%CE%B5%CF%80%CE%B9%CF%83%CE%BA%CE%B5%CF%85%CE%AE%CF%82-%CE%B1%CF%85%CF%84%CE%BF%CE%BA%CE%B9%CE%BD%CE%AE%CF%84%CE%BF%CF%85-524429947672359/'
									}
									target='_blank'
									rel='noopener'
									className='fa fa-facebook-f'
									aria-hidden='true'
								></Link>
								<Link
									to={'//www.youtube.com/channel/UCtqQbYNEFAtyTfyaS8VIKNQ/featured'}
									target='_blank'
									className='fab fa-youtube'
									aria-hidden='true'
									rel='noopener'
								></Link>
								<Link to={'//www.instagram.com/'} target='_blank' rel='noopener' className='fa fa-instagram' aria-hidden='true'></Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}

export default TopSection;
