import React, { useEffect, useState, useCallback } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { photos } from './photos';

const PhotoGallery = () => {
	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);

	const openLightbox = useCallback((event, { photo, index }) => {
		setCurrentImage(index);
		setViewerIsOpen(true);
	}, []);

	const closeLightbox = () => {
		setCurrentImage(0);
		setViewerIsOpen(false);
	};
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<section id='about-us' className='tabs-section dark'>
			<div className='auto-container'>
				<div className='row clearfix'>
					<div className='col-md-12 col-sm-12 col-xs-12'>
						<div className='sec-title'>
							<h2 className='skew-lines'>Φωτογραφίες</h2>
						</div>

						<div className='col-12'>
							<Gallery photos={photos} onClick={openLightbox} />
							<ModalGateway>
								{viewerIsOpen ? (
									<Modal onClose={closeLightbox}>
										<Carousel
											currentIndex={currentImage}
											views={photos.map((x) => ({
												...x,
												srcset: x.srcSet,
												caption: x.title,
											}))}
										/>
									</Modal>
								) : null}
							</ModalGateway>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PhotoGallery;
