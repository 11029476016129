import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function Services() {
	const location = useLocation();
	const [activeTab, setActiveTab] = useState(location?.state?.active || 'tab-1');
	const handleTabs = (tab) => setActiveTab(tab);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		const id = activeTab;
		const yOffset = -200;
		const element = document.getElementById(id);
		const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
		window.scrollTo({ top: y, behavior: 'smooth' });
	}, [activeTab]);

	return (
		<section className='tabs-section'>
			<div className='auto-container'>
				<div className='row'>
					<div className='tabs-box clearfix'>
						{/* <!--Buttons Side--> */}
						<div className='col-md-4 col-sm-6 col-xs-12 buttons-side'>
							<div className='sec-title'>
								<h3>εργασίες που κάνουμε</h3>
							</div>
							{/* <!--Tab Buttons--> */}
							<ul className='tab-buttons'>
								<li>
									<div className={`tab-btn clearfix ${activeTab === 'tab-1' && 'active-btn'}`} onClick={() => handleTabs('tab-1')}>
										<div className='icon'>
											<span className='fas fa-tools'></span>
										</div>
										<h4>Φανοποιεία - Βαφές</h4>
										<p>Επισκευή Αυτοκινήτου</p>
									</div>
								</li>
								<li>
									<div className={`tab-btn clearfix ${activeTab === 'tab-2' && 'active-btn'}`} onClick={() => handleTabs('tab-2')}>
										<div className='icon'>
											<span className='fas fa-puzzle-piece'></span>
										</div>
										<h4>Συγκολλήσεις</h4>
										<p>Αλλουμινίου και πλαστικού</p>
									</div>
								</li>
								<li>
									<div className={`tab-btn clearfix ${activeTab === 'tab-3' && 'active-btn'}`} onClick={() => handleTabs('tab-3')}>
										<div className='icon'>
											<span className='fas fa-fan'></span>
										</div>
										<h4>Service A/C</h4>
										<p>Αναπλήρωση Φρέον</p>
									</div>
								</li>
								<li>
									<div className={`tab-btn clearfix ${activeTab === 'tab-4' && 'active-btn'}`} onClick={() => handleTabs('tab-4')}>
										<div className='icon'>
											<span className='fas fa-car-crash'></span>
										</div>
										<h4>Διεκπαιρέωση Αποζημίωσης</h4>
										<p>Για όλες τις ασφαλιστικές</p>
									</div>
								</li>
								<li>
									<div className={`tab-btn clearfix ${activeTab === 'tab-5' && 'active-btn'}`} onClick={() => handleTabs('tab-5')}>
										<div className='icon'>
											<span className='fas fa-exchange-alt'></span>
										</div>
										<h4>Προετοιμασία Πώλησης</h4>
										<p>Ανανέωση Αυτοκινήτου</p>
									</div>
								</li>
								<li>
									<div className={`tab-btn clearfix ${activeTab === 'tab-6' && 'active-btn'}`} onClick={() => handleTabs('tab-6')}>
										<div className='icon'>
											<span className='fas fa-car'></span>
										</div>
										<h4>Αυτοκίνητο Εξυπηρέτησης</h4>
										<p>Για να μην μείνετε χωρίς αυτοκίνητο</p>
									</div>
								</li>
							</ul>
						</div>

						{/* <!--Content Side--> */}
						<div className='col-md-8 col-sm-6 col-xs-12 tabs-content clearfix' id='allcontent'>
							{activeTab === 'tab-1' && (
								<div className={`tab ${activeTab === 'tab-1' && 'active-tab'}`} id='tab-1'>
									<div className='tab-title'>
										<h2>Φανοποιείο - Βαφές</h2>
										<h3>Επισκευή Αυτοκινήτου με έμφαση στην λεπτομέρεια</h3>
									</div>
									<div className='text'>
										Η εταιρεία μας εξειδικεύεται σε τοπικές και ολικές βαφές ακόμα και στα πιο δύσκολα σημεία ή χρώματα του οχήματός σας,
										χρησιμοποιώντας τα καλύτερα και ποιοτικότερα χρώματα της αγοράς. Το βαφείο μας είναι πλήρως εξοπλισμένο, με φούρνο νέας
										τεχνολογίας για νεροδιάλυτα χρώματα με σύστημα αναρρόφησης σκόνης.
									</div>
									<div className='text'>
										Το φανοποιείο μας μπορεί να φέρει εις πέρας εργασίες αποκατάστασης ζημιών και ανακατασκευές παντός τύπου οχήματος. Είναι
										επανδρωμένο με σύγχρονα εργαλεία όπως καλίμπρα, μετρικό σύγκρισης αποστάσεων, ηλεκτρόποντα, mig, ηλεκτρικά εργαλεία και μεγάλη
										γκάμα τραβηχτικών εργαλείων για την άψογη αποκατάσταση των ζημιών. Τα υλικά που χρησιμοποιούμε είναι άριστης ποιότητας και πάντοτε
										φιλικά προς το περιβάλλον.
									</div>
									<div className='text'>
										Στόχος μας είναι η ικανοποίηση και άριστη εξυπηρέτηση των πελατών μας δίνοντας μεγάλη προσοχή στη λεπτομέρεια και χρησιμοποιώντας
										πάντα την τελευταία λέξη της τεχνολογίας.
									</div>
								</div>
							)}

							{activeTab === 'tab-2' && (
								<div className='tab active-tab' id='tab-2'>
									<div className='tab-title'>
										<h2>Συγκολήσεις</h2>
										<h3>Αλλουμινίου - Πλαστικού</h3>
									</div>

									<div className='text'>
										Πολλά πλαστικά μέρη του αυτοκινήτου, τα οποία κοστίζουν ακριβά, μπορούν να επιδιορθωθούν χωρίς την αγορά ανταλλακτικών, όπως
										σπασμένοι προφυλακτήρες, σπασμένες βάσεις φαναριών, διάφορα διακοσμητικά τριμ του αυτοκινήτου, πλαστικό πλαίσιο των πλαϊνών
										καθρεπτών κλπ. Επίσης, επισκευάζουμε φτερά και fairing μηχανών. Με τις ειδικές κόλλες και τα κατάλληλα εργαλεία, επιτυγχάνουμε το
										τέλειο κόλλημα όλων των πλαστικών μερών και ένα άριστο αποτέλεσμα για το όχημά σας.
									</div>
									<div className='text'>Ενδεικτικά αναφέρουμε:</div>

									<div className='row list clearfix'>
										<ul className='col-md-6 col-sm-12 col-xs-12'>
											<li>Προφυλακτήρες πλαστικοί</li>
											<li>Φανάρια</li>
											<li>Βεντιλατέρ</li>
											<li>Ιντερκούλερ</li>
										</ul>
										<ul className='col-md-6 col-sm-12 col-xs-12'>
											<li>Καθρέπτες</li>
											<li>Πλαστικά μοτοσυκλετών</li>
											<li>Μαρσπιέ</li>
											<li>Μπακαζιέρες</li>
										</ul>
									</div>
								</div>
							)}
							{activeTab === 'tab-3' && (
								<div className='tab active-tab' id='tab-3'>
									<div className='tab-title'>
										<h2>Service A/C</h2>
										<h3>Έλεγχος και Συντήρηση A/C και αναπλήρωση φρέον</h3>
									</div>

									<div className='text'>
										Όπως όλα τα συστήματα του αυτοκινήτου σας έτσι και το κλιματιστικό χρειάζεται την σωστή και τακτική του συντήρηση. Ο κομπρέσορας
										χρειάζεται λίπανση και τα φίλτρα πρέπει να είναι πάντα καθαρά. Αν τα φίλτρα μπλοκάρουν το πιθανότερο είναι να βρεθείτε χωρίς
										κλιματισμό όταν τον χρειάζεστε περισσότερο! Συνήθως το ψυκτικό ενός κλιματιστικού αυτοκινήτου χρειάζεται ολικό «γέμισμα», 4-5
										χρόνια μετά από την πρώτη κυκλοφορία και μετά κάθε 2-3 χρόνια.
									</div>
									<div className='text'>
										Στο κατάστημά μας, με την βοήθεια των ποιο σύγχρονων μηχανημάτων συντήρησης κλιματιστικών αυτοκίνητου, σας εγγυόμαστε να έχετε
										πάντα μια σωστή ψύξη στην καμπίνα των επιβατών, για άνετες και δροσερές διαδρομές.
									</div>
									<div className='text'>Ενδείξεις και συνήθεις βλαβες Α/C, λόγο κακής συντήρησης:</div>

									<div className='row list clearfix'>
										<ul className='col-md-6 col-sm-12 col-xs-12'>
											<li>Δεν βγαίνει κρύος αέρας</li>
											<li>Βλάβη στον συμπιεστή (κομπρεσέρ)</li>
										</ul>
										<ul className='col-md-6 col-sm-12 col-xs-12'>
											<li>Βλάβη στον ιμάντα κίνησης του κομπρεσέρ</li>
											<li>Βουλωμένα φίλτρα</li>
										</ul>
									</div>
								</div>
							)}
							{activeTab === 'tab-4' && (
								<div className='tab active-tab' id='tab-4'>
									<div className='tab-title'>
										<h2>Διεκπαιρέωση Αποζημίωσης</h2>
										<h3>Σε συνεργασία με όλες τις ασφαλιστικές</h3>
									</div>

									<div className='text'>
										Αναλαμβάνουμε την πλήρη αποζημίωση για την επισκευή του αυτοκινήτου σας, απευθείας μέσω της ασφαλιστικής σας εταιρίας. Χωρίς καμία
										δική σας οικονομική επιβάρυνση, διεκπεραιώνουμε όλες τις διαδικασίες της ζημιάς μέχρι την είσπραξη και την αποζημίωση.
									</div>
									<div className='text'>Η διαδικασία είναι απλή:</div>

									<div className='row list clearfix'>
										<ul className='col-md-12 col-sm-12 col-xs-12'>
											<li>Μας παραδίδετε το αυτοκίνητο.*</li>
											<li>Μας εξουσιοδοτείτε.</li>
											<li>Επικοινωνούμε με την ασφαλιστική σας και αναλαμβάνουμε να γίνει σωστά η διαδικασία αποζημίωσης και είσπραξης.</li>
											<li>Αποκαθιστούμε τη ζημιά.</li>
											<li>Πληρωνόμαστε απ’ ευθείας από την ασφαλιστική χωρίς να εμπλακείτε καθόλου.</li>
											<li>Παραλαμβάνετε το αυτοκίνητό σας.</li>
										</ul>
										<p className='col-md-12 col-sm-12 col-xs-12'>
											* Από τη στιγμή που η ασφαλιστική αποδεχθεί τη ζημιά και δηλώσει ότι αναλαμβάνει τα έξοδα!
										</p>
									</div>
								</div>
							)}
							{activeTab === 'tab-5' && (
								<div className='tab active-tab' id='tab-5'>
									<div className='tab-title'>
										<h2>Προετοιμασία Πώλησης</h2>
										<h3>Ανανέωση αυτοκινήτου για ευκολότερη πώληση</h3>
									</div>

									<div className='text'>
										Καθημερινά άνθρωποι γύρω μας αποφασίζουν να πουλήσουν το αυτοκίνητό τους. Τα στατιστικά στοιχεία δείχνουν ότι για κάθε ευρώ που θα
										ξοδέψετε για μικροεπισκευές στο αυτοκίνητό σας θα πάρετε 3 ευρώ περισσότερα στην τιμή πώλησης! Όχημα με καλή εμφάνιση, που λάμπει
										από καθαριότητα θα είναι πολύ πιο εύκολο να πουληθεί γρήγορα και σε καλύτερη τιμή. Για αυτό τον λόγο σας προσφέρουμε την υπηρεσια
										μας προετοιμασία οχήματος για πώληση.
									</div>
									<div className='text'> Ενέργειες που κάνουμε, για να εξασφαλίσετε καλύτερη τιμή για το αυτοκίνητό σας:</div>

									<div className='row list clearfix'>
										<ul className='col-md-12 col-sm-12 col-xs-12'>
											<li>Υπηρεσία καθαρισμού αυτοκινήτου.</li>
											<li>Αποκατάσταση φθορών από πέτρες, γρατζουνιές, βουλιαγμάτα και χτυπημάτα.</li>
											<li>Επαγγελματικές υπηρεσίες γυαλίσματος.</li>
											<li>Επισκευή ζαντών αλουμινίου.</li>
											<li>Αποκατάσταση των θαμπών και κιτρινισμένων φαναριών.</li>
											<li>Επισκευή κατεστραμμένου προφυλακτήρα. </li>
										</ul>
									</div>
								</div>
							)}
							{activeTab === 'tab-6' && (
								<div className='tab active-tab' id='tab-6'>
									<div className='tab-title'>
										<h2>Αυτοκίνητο Εξυπηρέτησης</h2>
										<h3>Για να μην μείνετε χωρίς αυτοκίνητο</h3>
									</div>

									<div className='text'>
										Σας παρέχουμε αυτοκίνητο εξυπηρέτησης, για να μπορείτε να μετακινηθείτε, μέχρι το αυτοκίνητό σας να επισκευαστεί.
									</div>
									<div className='row list clearfix'>
										<ul className='col-md-12 col-sm-12 col-xs-12'>
											<li>Απαλλαχθείτε από το άγχος της μετακίνησής σας.</li>
											<li>Χωρίς ενοχλητικές γραφειοκρατίες.</li>
											<li>Χωρίς να χάνετε χρόνο.</li>
											<li>Ασφαλή και ελεγμένα αυτοκίνητα για την εξυπηρέτηση σας. </li>
										</ul>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Services;
